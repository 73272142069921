import {
  allColleges,
  generateProgrammeDurations,
} from "@data/default/getAllAcademicProgrammes";

export const applicantFormSections = [
  {
    id: 1,
    sectionName: "Personal Information",
    isGroupedSection: false,
    sectionFields: [
      {
        sectionId: 1,
        type: "text",
        key: "lastName",
        label: "Last Name",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your last name",
        regexKey: "name",
      },
      {
        sectionId: 1,
        type: "text",
        key: "firstName",
        label: "First Name",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your first name",
        regexKey: "name",
      },
      {
        sectionId: 1,
        type: "text",
        key: "otherNames",
        label: "Other Names",
        isRequired: false,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter any other names (optional)",
        regexKey: "name",
      },
      {
        sectionId: 1,
        type: "select",
        key: "gender",
        label: "Gender",
        isRequired: true,
        hasOptions: true,
        disabled: false,
        options: ["Male", "Female"],
        placeholder: "Select your gender",
        regexKey: "select",
      },
      {
        sectionId: 1,
        type: "date",
        key: "dateOfBirth",
        label: "Date of Birth",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "MM-DD-YYYY",
        regexKey: "generalText",
      },
      {
        sectionId: 1,
        type: "number",
        key: "age",
        label: "Age",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your age",
        regexKey: "age",
      },
      {
        sectionId: 1,
        type: "text",
        key: "nationality",
        label: "Nationality",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your nationality",
        regexKey: "generalText",
      },
    ],
  },
  {
    id: 2,
    isGroupedSection: false,
    sectionName: "Contact Information",
    sectionFields: [
      {
        sectionId: 2,
        type: "text",
        key: "phoneNumber",
        label: "Phone Number",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your Phone number",
        regexKey: "phone",
      },
      {
        sectionId: 2,
        type: "text",
        key: "telecelNumber",
        label: "Telecel Number",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your Telecel number",
        regexKey: "phone",
      },
      {
        sectionId: 2,
        type: "text",
        key: "email",
        label: "Personal Email",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your personal email",
        regexKey: "email",
      },
    ],
  },
  {
    id: 3,
    sectionName: "Academic Information",
    isGroupedSection: false,
    sectionFields: [
      {
        sectionId: 3,
        type: "select",
        key: "modeOfAdmission",
        label: "Mode Of Admission",
        isRequired: true,
        hasOptions: true,
        disabled: false,
        options: ["Regular", "Fee-Paying", "Parallel", "Distance Learning"],
        placeholder: "Select your mode of admission",
        regexKey: "select",
      },
      {
        sectionId: 3,
        type: "select",
        key: "educationalLevel",
        label: "Educational Level",
        isRequired: true,
        hasOptions: true,
        disabled: false,
        options: ["Undergraduate", "Postgraduate"],
        placeholder: "Select your educational level",
        regexKey: "select",
      },
      {
        sectionId: 3,
        type: "select",
        key: "college",
        label: "College",
        isRequired: true,
        hasOptions: true,
        disabled: false,
        options: allColleges ?? [],
        placeholder: "Select your college",
        regexKey: "select",
      },
      {
        sectionId: 3,
        type: "text",
        key: "faculty",
        label: "Faculty",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your faculty name",
        regexKey: "name",
      },
      {
        sectionId: 3,
        type: "text",
        key: "department",
        label: "Department",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your department",
        regexKey: "name",
      },
      {
        sectionId: 3,
        type: "text",
        key: "programmeOfStudy",
        label: "Programme of Study",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your programme of study. E.g. BSc Statistics",
        regexKey: "programmeOfStudy",
      },
      {
        sectionId: 3,
        type: "select",
        key: "durationOfProgramme",
        label: "Duration of Programme",
        isRequired: true,
        hasOptions: true,
        options: generateProgrammeDurations(7) ?? [],
        disabled: false,
        placeholder: "Select duration of programme",
        regexKey: "select",
      },
      {
        sectionId: 3,
        type: "select",
        key: "yearOfStudy",
        label: "Year of Study",
        isRequired: true,
        hasOptions: true,
        disabled: false,
        options: [1, 2, 3, 4, 5, 6],
        placeholder: "Select your year/level of study (1-10)",
        regexKey: "select",
      },
      {
        sectionId: 3,
        type: "text",
        key: "indexNumber",
        label: "Index Number",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your index number",
        regexKey: "generalText",
      },
      {
        sectionId: 3,
        type: "text",
        key: "referenceNumber",
        label: "Reference Number",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your reference number",
        regexKey: "generalText",
      },
    ],
  },
  {
    id: 4,
    sectionName: "Parent/Guardian Contact Information",
    isGroupedSection: true,
    groupedKey: "guardianDetails",
    sectionFields: [
      {
        sectionId: 4,
        type: "text",
        key: "guardianName",
        label: "Guardian's Name",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter guardian's full name",
        regexKey: "name",
      },
      {
        sectionId: 4,
        type: "text",
        key: "guardianPhone",
        label: "Guardian's Phone Number",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter guardian's phone number",
        regexKey: "phone",
      },
      {
        sectionId: 4,
        type: "text",
        key: "guardianEmail",
        label: "Guardian's Email",
        isRequired: false,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter guardian's email",
        regexKey: "email",
      },
      {
        sectionId: 4,
        type: "text",
        key: "guardianLocation",
        label: "Guardian's Location",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter guardian's address/location",
        regexKey: "generalText",
      },
      {
        sectionId: 4,
        type: "text",
        key: "guardianOccupation",
        label: "Guardian's Occupation",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter guardian's occupation",
        regexKey: "generalText",
      },
      {
        sectionId: 4,
        type: "select",
        key: "guardianRelationship",
        label: "Relationship to Applicant",
        isRequired: true,
        hasOptions: true,
        disabled: false,
        options: [
          "Father",
          "Mother",
          "Uncle",
          "Aunt",
          "Sibling",
          "Grandfather",
          "Grandmother",
          "Cousin",
          "Stepfather",
          "Stepmother",
          "Guardian",
          "Foster Parent",
          "Legal Guardian",
          "Host Parent",
          "Mentor",
          "Sponsor",
          "Caretaker",
          "Religious Leader",
          "Family Friend",
          "Other",
        ],
        placeholder: "Select relationship",
        regexKey: "select",
      },
    ],
  },
];
