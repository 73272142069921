export const adminFormSections = [
  {
    id: 1,
    sectionName: "Personal Information",
    isGroupedSection: false,
    sectionFields: [
      {
        sectionId: 1,
        type: "text",
        key: "lastName",
        label: "Last Name",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your last name",
        regexKey: "name",
      },
      {
        sectionId: 1,
        type: "text",
        key: "firstName",
        label: "First Name",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your first name",
        regexKey: "name",
      },
      {
        sectionId: 1,
        type: "text",
        key: "otherNames",
        label: "Other Names",
        isRequired: false,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter any other names (optional)",
        regexKey: "name",
      },
      {
        sectionId: 1,
        type: "select",
        key: "gender",
        label: "Gender",
        isRequired: true,
        hasOptions: true,
        disabled: false,
        options: ["Male", "Female"],
        placeholder: "Select your gender",
        regexKey: "select",
      },
      {
        sectionId: 1,
        type: "date",
        key: "dateOfBirth",
        label: "Date of Birth",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "MM-DD-YYYY",
        regexKey: "generalText",
      },
      {
        sectionId: 1,
        type: "text",
        key: "nationality",
        label: "Nationality",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your nationality",
        regexKey: "generalText",
      },
    ],
  },
  {
    id: 2,
    isGroupedSection: false,
    sectionName: "Contact Information",
    sectionFields: [
      {
        sectionId: 2,
        type: "text",
        key: "phoneNumber",
        label: "Phone Number",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your Phone number",
        regexKey: "phone",
      },
      {
        sectionId: 2,
        type: "text",
        key: "email",
        label: "Personal Email",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your personal email",
        regexKey: "email",
      },
      {
        sectionId: 2,
        type: "text",
        key: "authEmail",
        label: "Authentication Email",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your email used for authentication",
        regexKey: "email",
      },
      {
        sectionId: 4,
        type: "text",
        key: "location",
        label: "Location/Address",
        isRequired: true,
        hasOptions: false,
        disabled: false,
        placeholder: "Enter your address/location",
        regexKey: "generalText",
      },
    ],
  },
  {
    id: 3,
    isGroupedSection: false,
    sectionName: "Admin Role Assignment",
    sectionFields: [
      {
        sectionId: 3,
        type: "select",
        key: "assignedRole",
        label: "Assigned Role",
        isRequired: true,
        hasOptions: true,
        options: ["Super Admin", "Approval Manager", "Review Manager"],
        disabled: false,
        placeholder: "Select an admin role",
        regexKey: "select",
      },
    ],
  },
];
