const academicProgrammesRecords = {
  "College of Engineering": {
    undergraduate: [
      "BSc Civil Engineering",
      "BSc Electrical/Electronic Engineering",
      "BSc Mechanical Engineering",
      "BSc Computer Engineering",
      "BSc Chemical Engineering",
      "BSc Agricultural Engineering",
      "BSc Biosystems Engineering",
      "BSc Telecommunication Engineering",
      "BSc Materials Engineering",
      "BSc Geomatic Engineering",
      "BSc Biomedical Engineering",
      "BSc Environmental Engineering",
      "BSc Computer Science Engineering",
    ],
    postgraduate: [
      "MSc Civil Engineering",
      "MSc Electrical/Electronic Engineering",
      "MSc Mechanical Engineering",
      "MSc Computer Engineering",
      "MSc Chemical Engineering",
      "MSc Agricultural Engineering",
      "MSc Biosystems Engineering",
      "MSc Telecommunications Engineering",
      "MSc Materials Engineering",
      "MSc Geomatic Engineering",
      "MSc Biomedical Engineering",
      "MSc Environmental Engineering",
      "MSc Structural Engineering",
      "MSc Water and Sanitation Engineering",
      "MSc Energy and Environmental Engineering",
      "MSc Project Management",
      "PhD in Engineering",
      "PhD in Civil Engineering",
      "PhD in Electrical/Electronic Engineering",
      "PhD in Mechanical Engineering",
      "PhD in Computer Engineering",
      "PhD in Chemical Engineering",
      "PhD in Agricultural Engineering",
      "PhD in Biosystems Engineering",
    ],
  },
  "College of Health Sciences": {
    undergraduate: [
      "MB ChB (Medicine)",
      "BSc Nursing",
      "BSc Midwifery",
      "BSc Medical Laboratory Technology",
      "BSc Public Health",
      "BPharm (Pharmacy)",
      "BDS (Dentistry)",
      "BSc Health Information Management",
      "BSc Radiography",
      "BSc Speech and Language Therapy",
      "BSc Dietetics",
      "BSc Medical Imaging",
      "BSc Physiotherapy",
    ],
    postgraduate: [
      "MSc Public Health",
      "MSc Medical Microbiology",
      "MSc Clinical Biochemistry",
      "MSc Medical Laboratory Science",
      "MSc Health Informatics",
      "MSc Nursing",
      "MSc Pharmacy",
      "MMed (Masters of Medicine)",
      "PhD in Health Sciences",
      "MSc Clinical Psychology",
      "MSc Occupational Therapy",
      "MSc Radiography",
      "MSc Physiotherapy",
      "MSc Dietetics",
      "PhD in Public Health",
      "PhD in Medical Sciences",
    ],
  },
  "College of Science": {
    undergraduate: [
      "BSc Biological Sciences",
      "BSc Physics",
      "BSc Chemistry",
      "BSc Mathematics",
      "BSc Statistics",
      "BSc Computer Science",
      "BSc Environmental Science",
      "BSc Earth Science",
      "BSc Biochemistry",
      "BSc Marine Science",
      "BSc Geography and Rural Development",
      "BSc Agricultural Biotechnology",
      "BSc Information Technology",
    ],
    postgraduate: [
      "MSc Environmental Science",
      "MSc Computer Science",
      "MSc Statistics",
      "MSc Mathematics",
      "MSc Biochemistry",
      "MSc Chemistry",
      "MSc Physics",
      "MSc Applied Mathematics",
      "MSc Geo-Informatics",
      "MSc Biotechnology",
      "PhD in Science",
      "PhD in Environmental Science",
      "PhD in Computer Science",
      "PhD in Statistics",
      "PhD in Mathematics",
      "PhD in Biochemistry",
      "PhD in Chemistry",
      "PhD in Physics",
    ],
  },

  "College of Agriculture and Natural Resources": {
    undergraduate: [
      "BSc Agricultural Economics and Extension",
      "BSc Agricultural Engineering",
      "BSc Horticulture",
      "BSc Animal Science",
      "BSc Crop Science",
      "BSc Soil Science",
      "BSc Agricultural Biotechnology",
      "BSc Agricultural Extension",
      "BSc Forest Resources Technology",
      "BSc Fisheries and Aquatic Sciences",
      "BSc Agricultural Science",
    ],
    postgraduate: [
      "MSc Agricultural Economics",
      "MSc Agronomy",
      "MSc Animal Science",
      "MSc Soil Science",
      "MSc Horticulture",
      "MSc Fisheries and Aquatic Sciences",
      "MSc Agricultural Biotechnology",
      "MSc Agricultural Extension",
      "MSc Forest Resources Technology",
      "MSc Irrigation and Water Management",
      "PhD in Agriculture",
      "PhD in Agricultural Economics",
      "PhD in Agronomy",
      "PhD in Animal Science",
      "PhD in Soil Science",
      "PhD in Horticulture",
    ],
  },
  "College of Arts and Built Environment": {
    undergraduate: [
      "BA Architecture",
      "BA Planning",
      "BA Fine Arts",
      "BA Communication Design",
      "BA Landscape Architecture",
      "BSc Construction Technology",
      "BSc Environmental Design",
      "BSc Interior Design",
      "BSc Building Technology",
      "BSc Urban and Regional Planning",
    ],
    postgraduate: [
      "MSc Architecture",
      "MSc Planning",
      "MSc Urban Design",
      "MSc Fine Arts",
      "MSc Interior Architecture",
      "MSc Construction Management",
      "MSc Landscape Architecture",
      "MSc Real Estate Development",
      "PhD in Architecture",
      "PhD in Urban and Regional Planning",
      "PhD in Fine Arts",
    ],
  },
  "College of Social Sciences": {
    undergraduate: [
      "BSc Economics",
      "BSc Sociology",
      "BSc Psychology",
      "BSc Political Science",
      "BSc Hospitality Management",
      "BSc Business Administration (Accounting)",
      "BSc Business Administration (Banking and Finance)",
      "BSc Business Administration (Human Resource Management)",
      "BSc Business Administration (Marketing)",
      "BSc Business Administration (Entrepreneurship)",
      "BSc Business Administration (Logistics and Supply Chain Management)",
    ],
    postgraduate: [
      "MSc Economics",
      "MSc Development Studies",
      "MSc Sociology",
      "MSc Political Science",
      "MSc Business Administration (MBA)",
      "MSc Business Administration (Accounting)",
      "MSc Business Administration (Banking and Finance)",
      "MSc Business Administration (Human Resource Management)",
      "MSc Business Administration (Marketing)",
      "MSc Business Administration (Entrepreneurship)",
      "MSc Business Administration (Logistics and Supply Chain Management)",
      "PhD in Social Sciences",
    ],
  },
};

export default academicProgrammesRecords;
